
import {defineComponent, onMounted, ref} from "vue";
import ActionTemplateGroupService from "@/core/services/ActionTemplateGroupService";
import Entities from "@/components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import {ActionTemplates} from "@/core/composite/composite";
import BaseMultiSelect from "@/components/base/select/BaseMultiSelect.vue";
import Swal from "sweetalert2";
import BaseRadio from "@/components/base/form/BaseRadio.vue";

export default defineComponent({
  name: "ActionTemplateGroups",
  components: { BaseRadio, BaseMultiSelect, FormErrorMessage, BaseForm, BaseModal, QuickAction, Entities, Field},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Action Groups",
        [
          {link: false, router: '', text: 'List'}
        ]
      )
    })
    const validate = {}
    const data = ref<any>([]);
    const loading = ref(true);
    const state = ref({
      submitting: false,
      id: '',
      model: {name: 'code', code: '', area: 'CASE'},
      actions: {actions: []}
    })
    const loadGroup = () => {
      ActionTemplateGroupService.getList().then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    loadGroup();
    return {
      data,
      loadGroup,
      validate,
      state,
      ...ActionTemplates("CASE")
    }

  },
  methods: {
    onAdd() {
      this.state.id = ''
      this.state.model = {name: '', code: '', area: 'CASE'}
      const modal = this.$refs.actionTemplateGroupRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onAddAction(data) {
      this.loadActionTemplate(data.area);
      this.state.id = data.id;
      let ids: any = [];
      data.actions.forEach(item => {
        ids.push(item.id)
      })
      this.state.actions.actions = []
      this.state.actions.actions = ids;
      const modal = this.$refs.addActionGroupRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSave() {
      this.state.submitting = true;
      if (!this.state.id) {
        ActionTemplateGroupService.create(this.state.model).then(() => {
          this.loadGroup();
          const modal = this.$refs.actionTemplateGroupRef as typeof BaseModal;
          modal.hideBaseModal();
        }).finally(() => {
          this.state.submitting = false
        })
      }
    },
    onSaveActions() {
      this.state.submitting = true;
      ActionTemplateGroupService.setActions(this.state.id, this.state.actions).then(() => {
        this.loadGroup()
        const modal = this.$refs.addActionGroupRef as typeof BaseModal;
        modal.hideBaseModal();
      }).finally(() => {
        this.state.submitting = false
      })
    },

    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ActionTemplateGroupService.delete(id).then(() => {
            this.loadGroup()
          })
        }
      })
    }
  }
})
